<template>
  <div class="service-box">
    <div class="img-box" v-for="(item, index) in list" :key="index">
      <img :src="item.url" />
      <div class="bg flex flex-col row-center col-center">
        <div v-show="showList[index]">
          <div class="title">
            {{ item.title }}
          </div>
          <div class="desc">
            {{ item.desc }}
          </div>
        </div>
      </div>
    </div>
    <div class="img-box flex flex-col row-center col-center">
      <div class="more">{{ $t('addService.info') }}</div>
      <phone />
    </div>
  </div>
</template>

<script>
import Phone from '@/components/Common/Phone/phone.vue'

export default {
  name: 'AddService',
  components: { Phone },
  data() {
    return {
      position: 0,
      showList: [true, false, false, false, false]
    }
  },
  computed: {
    list() {
      return [
        {
          title: this.$t('addService.swiper1.title'),
          desc: this.$t('addService.swiper1.desc'),
          url: process.env.VUE_APP_FILE_URL + 'travel/1.png',
          position: 0
        },
        {
          title: this.$t('addService.swiper2.title'),
          desc: this.$t('addService.swiper2.desc'),
          url: process.env.VUE_APP_FILE_URL + 'travel/2.png',
          position: 0.4
        },
        {
          title: this.$t('addService.swiper3.title'),
          desc: this.$t('addService.swiper3.desc'),
          url: process.env.VUE_APP_FILE_URL + 'travel/3.png',
          position: 0.9
        },
        {
          title: this.$t('addService.swiper4.title'),
          desc: this.$t('addService.swiper4.desc'),
          url: process.env.VUE_APP_FILE_URL + 'travel/4.png',
          position: 1.4
        },
        {
          title: this.$t('addService.swiper5.title'),
          desc: this.$t('addService.swiper5.desc'),
          url: process.env.VUE_APP_FILE_URL + 'travel/5.png',
          position: 1.9
        }
      ]
    }
  },
  created() {},
  props: {
    scrollY: {
      type: Number,
      default: 0
    }
  },
  watch: {
    scrollY(val) {
      this.position = (val / document.documentElement.clientHeight / 2).toFixed(1)
      this.list.forEach((item, index) => {
        if (item.position <= this.position) {
          this.showList[index] = true
        }
      })
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped lang="scss">
.service-box {
  .img-box {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: $bg-color;
    img {
      width: 100vw;
      height: 100vh;
    }
    .bg {
      width: 610px;
      height: 650px;
      border: 3px solid #bbb290;
      box-sizing: border-box;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 0 445.205px 445.205px 0;
      color: #ffffff;
      //padding: 0 140px;
      position: absolute;
      top: calc((100vh - 650px) / 2);
      left: calc((100vw - 610px) / 2);
      margin-top: 1%;
      font-weight: bold;
      .title {
        font-size: 40px;
        line-height: normal;
        margin-bottom: 12px;
        width: 100%;
        margin-top: -30px;
        opacity: 0;
        animation: showText 1s linear 100ms forwards;
        white-space: pre-line;
      }
      .desc {
        font-size: 28px;
        line-height: normal;
        animation: showText 1s linear 100ms forwards;
        opacity: 0;
        white-space: pre-line;
      }
      @keyframes showText {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }
  .more {
    color: #ffffff;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    margin-bottom: 100px;
  }
}
.en,
.ja {
  .bg {
    //padding: 0 0 0 100px !important;
    font-weight: 500 !important;
  }
}
</style>
