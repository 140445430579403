<template>
  <div>
    <add-service :scrollY="scrollY" />
  </div>
</template>

<script>
import AddService from '@/components/Moudle/AddService/index.vue'

export default {
  components: { AddService },
  data() {
    return {}
  },
  props: {
    scrollY: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style scoped lang="scss"></style>
